import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const Tags = () => {
  return (
    <Layout>
      <SEO title="Tags" />
      <main className="page">
        <section className="tags-page">
        </section>
      </main>
    </Layout>
  )
}

export default Tags
